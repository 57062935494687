import { createContext, useContext, useEffect, useState } from "react"
import { useContextex } from "./useContext";
import { CustomSnackbar } from "../utils/CustomSnackbar";
import { uid } from "uid";
import { getLoginUserData, getRegisterData } from "../store/selector";
import { useDispatch, useSelector } from "react-redux";
import { logoutAc } from "../store/action";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const { setLoginUserData } = useContextex();
    const [isAuth, setIsAuth] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("")

    const loginData = useSelector(getLoginUserData);
    const registerData = useSelector(getRegisterData);
    const dispatch = useDispatch()

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('authToken')
        if (token) {
            setIsAuth(true);
        }
    }, [])

    const login = (token) => {
        localStorage.setItem('authToken', token);
        setIsAuth(true);
        setShowSnackbar(true)
        setSnackMsg(loginData?.ResponseMsg)
    }

    const register = (token) => {
        localStorage.setItem('authToken', token);
        setIsAuth(true);
        setShowSnackbar(true)
        setSnackMsg(registerData?.ResponseMsg)
    }

    const logout = () => {
        dispatch(logoutAc())
        localStorage.clear()
        setSnackMsg("You have logged out successfully!")
    };

    return (
        <>
            <AuthContext.Provider value={{ isAuth, login, logout, register }}>
                {children}
            </AuthContext.Provider>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}
        </>

    )

}