import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { checkMobileNumRequest, forgotPasswordRequest, msg91OtpRequest, twillioOtpRequest } from '../store/action';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getForgotPassData, getOTPData, getVarifyMobData } from '../store/selector';

const ForgotPass = ({ otpType }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showMobileInput, setShowMobileInput] = useState(true)
    const [showOTPInput, setShowOTPInput] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showResetPassInput, setShowResetPassInput] = useState(false)
    const [sendOtpReq, setSendOtpReq] = useState(false);

    const [otpValue, setOtpValue] = useState(Array.from({ length: 6 }, () => ''));
    const [userOtp, setUserOtp] = useState('');
    const [activeName, setActiveName] = useState('mobile number')

    const [snackMsg, setSnackMsg] = useState(null)
    const [validNumberData, setValidNumberData] = useState(null);
    const [resendOpt, setResendOtp] = useState(false)
    const [resetPassWordNow, setResetPassWordNow] = useState(null)
    const [resetPassWordData, setResetPassWordData] = useState(null)

    const [passwordValue, setPasswordValue] = useState('')
    const [rewritePassValue, setRewritePassValue] = useState('')

    const [checkNumber, setCheckNumber] = useState(null);
    const [showModal, setShowModal] = useState(true);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inputRefs = useRef(Array.from({ length: 6 }, () => null));
    let concatenatedOtp = otpValue.join('');

    const otpData = useSelector(getOTPData)
    const varifyMobileDt = useSelector(getVarifyMobData)
    const forgotPassData = useSelector(getForgotPassData)

    useEffect(() => {
        const modalElement = document.getElementById('modalregister');
        const modal = new Modal(modalElement);
        modal.show();

    }, []);

    useEffect(() => {
        setShowModal(true)
    }, [])

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            if (sendOtpReq || validNumberData === 'Already Exist Mobile Number!' || resendOpt) {
                try {
                    const mobileNumber = phoneNumber;
                    const Ccode = phoneNumber.slice(0, 2);
                    const countryCallingCode = `+${Ccode}`;
                    if (otpType === 'Twilio') {
                        await dispatch(twillioOtpRequest({ mobile: mobileNumber }));
                        setResendOtp(false)
                    } else {
                        await dispatch(msg91OtpRequest({ mobile: mobileNumber }));
                        setResendOtp(false)
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, otpType, sendOtpReq, validNumberData, resendOpt]);

    useEffect(() => {
        if (otpData) {
            setUserOtp(otpData);
        } else {
            console.error("OTP data is null or undefined.");
        }
    }, [otpData]);

    const Ccode = phoneNumber.slice(0, 2);
    const countryCallingCode = `+${Ccode}`;
    const mobileNumber = phoneNumber.slice(2);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {

            if (checkNumber) {
                try {
                    await dispatch(checkMobileNumRequest({ mobile: mobileNumber, ccode: countryCallingCode }));
                    setShowSnackbar(true)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, checkNumber, phoneNumber]);

    useEffect(() => {
        if (varifyMobileDt && varifyMobileDt.ResponseMsg) {
            setValidNumberData(varifyMobileDt.ResponseMsg);
            setSnackMsg(varifyMobileDt.ResponseMsg)
        } else {
            console.error("Valid number data is null or undefined.");
        }
    }, [varifyMobileDt]);

    useEffect(() => {
        const fetchResetPasswordNow = async () => {
            if (resetPassWordNow && passwordValue === rewritePassValue) {
                try {
                    await dispatch(forgotPasswordRequest({ mobile: mobileNumber, ccode: countryCallingCode, password: passwordValue }));
                    setShowSnackbar(true);
                    setSendOtpReq(false)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchResetPasswordNow()
    }, [dispatch, resetPassWordNow])

    useEffect(() => {
        if (forgotPassData && forgotPassData?.ResponseMsg) {
            setResetPassWordData(forgotPassData?.ResponseMsg)
            setSnackMsg(forgotPassData?.ResponseMsg)
            setPhoneNumber('')
        } else {
            console.error("Valid number data is null or undefined.");
        }
    }, [forgotPassData])

    useEffect(() => {
        if (resetPassWordData == 'Password Changed Successfully!!!!!') {
            setShowModal(false)
        }
    }, [resetPassWordData])

    useEffect(() => {
        if (validNumberData == "Already Exist Mobile Number!") {
            setShowOTPInput(true)
            setActiveName('opt input')
        }
    }, [validNumberData])

    const handleLoginClick = () => {
        setCheckNumber(true);
    };

    const handleChange = (index, value) => {
        const updatedOtpValue = [...otpValue];
        updatedOtpValue[index] = value;
        setOtpValue(updatedOtpValue);
        if (value && !isNaN(value) && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && !event.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleVarify = () => {
        if (userOtp.otp == concatenatedOtp) {
            setShowResetPassInput(true)
            setShowSnackbar(true)
            setSnackMsg('Your OTP has been successfully verified')
            setActiveName('reset now')
            setShowOTPInput(false)
            setValidNumberData(null)
            setSendOtpReq(false)
            setCheckNumber(false)
        }else{
            setShowSnackbar(true)
            setSnackMsg('Oops! Please provide a valid OTP.')    
        }
    }

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            {showModal && (
                <div className="modal fade show modalCenter" id="modalregister" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal-sm">
                            <a className="btn-hide-modal pointer" data-bs-dismiss="modal" ><i className="icon-close"></i></a>
                            <div className="image-left">
                                <img src="./assets/images/section/login.jpg" alt="" />
                                <h3>{t('Welcome to Your Real Estate Website')}</h3>
                            </div>
                            <div className="content-right">
                                <h4>{t('Reset Your Password')}</h4>
                                <form className="form-login">
                                    {showMobileInput && (<>

                                        <fieldset className="phonenumber">
                                            <input
                                                type='text'
                                                placeholder="Phone Number"
                                                name="phone"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                maxLength={13}
                                                minLength={13}
                                                tabIndex="2"
                                                aria-required="true"
                                            />
                                        </fieldset>

                                    </>)}

                                    {showOTPInput && <div className='d-flex'>
                                        <form className="form__login">
                                            <div className='row'>
                                                <div className="otp-field mb-4">
                                                    <p>*{t('Enter Your Otp Value')}</p>
                                                    {inputRefs.current.map((ref, index) => (
                                                        <input
                                                            key={index}
                                                            type="text"
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                            maxLength={1}
                                                            value={otpValue[index]}
                                                            onChange={(e) => handleChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                            style={{ height: '50px', width: '50px' }}
                                                            className='m-1'
                                                        />
                                                    ))}
                                                    <p className='mt-2'>{t(`Didn't receive code`)}?<span onClick={() => setResendOtp(true)} className='text-primary pointer'>{t('Resend New Code')}</span></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>}


                                    {showResetPassInput &&
                                        <>
                                            <p>*{t('Reset Password Now')}</p>
                                            <fieldset className="password">
                                                <input type="password" value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} placeholder="Enter Your New Password" />
                                            </fieldset>
                                            <fieldset className="password">
                                                <input type="password" value={rewritePassValue} onChange={(e) => setRewritePassValue(e.target.value)} placeholder="Rewrite Your Password" />
                                            </fieldset>

                                        </>
                                    }

                                    {activeName === 'mobile number' && (
                                        <div className="button-submit w-full">
                                            <button className="tf-button-primary w-full justify-content-center" type="button" onClick={handleLoginClick}>
                                                {t('Check Number')} <i className="icon-arrow-right-add"></i>
                                            </button>
                                        </div>
                                    )}

                                    {activeName === 'opt input' && (
                                        <button className="tf-button-primary w-full justify-content-center" type="button" onClick={handleVarify}>
                                            {t('Verify OTP')} <i className="icon-arrow-right-add"></i>
                                        </button>
                                    )}

                                    {activeName === 'reset now' && (
                                        <button className="tf-button-primary w-full justify-content-center" type="button" onClick={() => setResetPassWordNow(true)}>
                                            {t('Reset')} <i className="icon-arrow-right-add"></i>
                                        </button>
                                    )}
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}
        </>
    );
};

export default ForgotPass;
