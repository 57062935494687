import { IconWallet } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { ReModal } from '../partials/ReModal';
import { useNavigate } from 'react-router-dom';
import { fetchData, fetchSecoundData } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { useContextex } from '../context/useContext';
import { RazorpayPayment } from '../payment/RazorpayPayment';
import { KhaltiPayment } from '../payment/KhaltiPayment';
import { FlutterwavePayment } from '../payment/FlutterwavePayment';
import { PaystackPayment } from '../payment/PaystackPayment';
import { StripePayment } from '../payment/StripePayment';
import { PaypalPayment } from '../payment/PaypalPayment';
import { PaytmPayment } from '../payment/PaytmPayment';
import SenangpayPayment from '../payment/SenangpayPayment';
import { MidtransPayment } from '../payment/MidtransPayment';
import { TwoCheckoutPayment } from '../payment/TwoCheckoutPayment';
import PayfastPayment from '../payment/PayfastPayment';
import { MercadoPagoPayment } from '../payment/MercadoPagoPayment';
import { AddToProductPay } from '../payment/AddToWallet';
import Footer from '../partials/Footer';
import { useTranslation } from 'react-i18next';
import { getData, getLoading, getSecoundData } from '../store/selector';

export const CartProduct = () => {
    const [couponAdd, setCouponAdd] = useState(null)

    const [couponId, setCouponId] = useState('')
    const [couponValue, setCouponValue] = useState('')
    const [couponCode, setCouponCode] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState('');

    const [couponData, setCouponData] = useState([])
    const [couponResponsData, setCouponResponsData] = useState([])

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const [walletPay, setWalletPay] = useState(false)
    const [couponStatus, setCouponStatus] = useState(false)
    const [showPaymentList, setShowPaymentList] = useState(false)
    const [paymentTrigger, setPaymentTrigger] = useState(false)

    const { bookedUserData, bookedProductData, imageBaseUrl, isUserId, setProductAmount, productAmount, loginUserData, userCurrency, setProductFinalPrice, referApiData, paymentGatwayList, setSelectedPaymentId, selectedPaymentId, userWalletAmount } = useContextex();

    const openModal = () => setIsOpenModal(!isOpenModal);
    const closeModal = () => setIsOpenModal(false);
    const { t } = useTranslation();

    const data = useSelector(getData)
    const secoundData = useSelector(getSecoundData)
    const loading = useSelector(getLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchData('u_couponlist.php', { uid: isUserId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch]);

    useEffect(() => {
        if (data && data?.couponlist) {
            setCouponData(data?.couponlist);
        } else {
            console.error("Data or couponData is null or undefined.");
        }
    }, [data]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {

            if (couponId) {
                try {
                    await dispatch(fetchSecoundData('u_check_coupon.php', { uid: isUserId, cid: couponId }));
                    setCouponStatus(true)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }

        };

        fetchDataAndSetCategories();
    }, [dispatch, couponId]);

    useEffect(() => {
        if (secoundData) {
            setCouponResponsData(secoundData);
        } else {
            console.error("Data or  secound HomeData is null or undefined.");
        }
    }, [secoundData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const productImage = bookedProductData?.propetydetails?.image[0]?.image
    const producttitle = bookedProductData?.propetydetails?.title
    const productCity = bookedProductData?.propetydetails?.city
    const productPrice = (bookedProductData?.propetydetails?.price) * (bookedUserData?.days);
    const productTax = productPrice * referApiData?.tax / 100;
    const productTotalCost = productPrice + productTax;
    const walletBalance = userWalletAmount ? userWalletAmount : loginUserData?.UserLogin?.wallet
    const paybleCouponAmnt = couponStatus && couponAdd && (couponValue > productTotalCost ? productTotalCost : couponValue)
    const paybleWalletAmnt = isChecked && walletBalance > productTotalCost ? productTotalCost : (isChecked && walletBalance ? walletBalance : 0)

    let finalPrice = productTotalCost;

    if (isChecked && couponStatus && couponAdd) {
        finalPrice = productTotalCost - paybleWalletAmnt - paybleCouponAmnt;
    } else if (isChecked) {
        finalPrice = productTotalCost - paybleWalletAmnt;
    } else if (couponStatus && couponAdd) {
        finalPrice = productTotalCost - paybleCouponAmnt;
    }

    setProductFinalPrice(`${finalPrice}`)

    useEffect(() => {
        setProductAmount(prevState => ({
            ...prevState,
            bookingDays: bookedUserData?.days || '',
            bookingAmount: bookedProductData?.propetydetails?.price || '',
            bookingTotalAmount: productTotalCost || '',
            bookingTax: referApiData?.tax,
            bookingWalletAmount: paybleWalletAmnt || '0',
            bookingCouponAmount: paybleCouponAmnt || '0',
            productTotalAmnt: productPrice || '',
            productTotalTax: productTax || ''
        }));
    }, [paybleCouponAmnt, paybleWalletAmnt, referApiData, productTotalCost, bookedProductData]);

    const handleClick = async (id, value, ccode) => {
        setCouponId(id)
        setCouponValue(value)
        setCouponCode(ccode)
        setCouponAdd(!couponAdd)
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setCouponCode('')
            setCouponValue('')
            setCouponAdd('')
        } else {
            console.log('Checkbox is unchecked');
        }
    };

    const handleToBook = () => {
        if (isChecked && productTotalCost === paybleWalletAmnt) {
            setWalletPay(true)
            setSelectedPaymentType('wallet')
            setSelectedPaymentId('5')
        } else {
            setShowPaymentList(!showPaymentList)
            setWalletPay(false)
        }
    }

    const secoudResponseMessage = couponResponsData?.Result
    const navigate = useNavigate()
    const filteredPaymentWay = paymentGatwayList?.filter(item => item?.p_show === '1')

    return (
        <>
            <div className="main-content">

                <div className="flat-title">
                    <div className="cl-container full">
                        <div className="row">
                            <div className="col-12">
                                <div className="content">
                                    <h2>{t('Review Summary')}</h2>
                                    <ul className="breadcrumbs">
                                        <li><a className='pointer' onClick={() => navigate('/')}>Home</a></li><li>/</li><li>Review Summary</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form className="form-shop-cart">
                    <div className="cl-container">
                        <div className="row">
                            <div className="col-lg-8">

                                <div className="table-shop-cart default mb-30">
                                    <div className="head">
                                        <div className="item">
                                            <div className="text">{t('Product')}</div>
                                        </div>
                                        <div className="item">
                                            <div className="text">{t('Price')}</div>
                                        </div>
                                        <div className="item">
                                            <div className="text">{t('Booking Days')}</div>
                                        </div>
                                        <div className="item">
                                            <div className="text">{t('Subtotal')}</div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="shop-cart-item item">
                                                <div className="image">
                                                    <img src={`${imageBaseUrl}${productImage}`} alt="" />
                                                </div>
                                                <div>
                                                    <div className="title">
                                                        <a >{producttitle}</a>
                                                        <p >{productCity}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>{userCurrency}{bookedProductData?.propetydetails?.price}</p>
                                                </div>
                                                <div>
                                                    <p>{bookedUserData?.days}</p>
                                                </div>
                                                <div>
                                                    <div className="flex justify-between items-center">
                                                        <p>{userCurrency}{productPrice}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>

                                <div className="bottom">

                                    <div className="flex gap20 flex-grow flex-wrap">
                                        <fieldset className="name">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="justify-center"
                                                name="text"
                                                tabIndex="2"
                                                value={couponCode ? couponCode : "Add Coupon"}
                                                aria-required="true"
                                                required
                                                disabled
                                            />
                                        </fieldset>
                                        <button className="tf-button-primary justify-center style-black" type="button" onClick={() => setIsOpenModal(!isOpenModal)}>
                                            {t('Apply Coupon')} <i className="icon-arrow-right-add"></i>
                                        </button>
                                        {couponStatus && couponAdd &&
                                            <p className='text-primary m-0 p-0 '>Coupon Applied Successfully!!</p>
                                        }
                                    </div>
                                    {walletBalance > 0 && (
                                        <form>
                                            <ul className="ft-download bg-light  " style={{ borderRadius: '15px' }} >

                                                <li style={{ width: 'auto' }}>
                                                    <a>
                                                        <div className="icon text-dark">
                                                            {/* Assuming IconWallet is an imported component */}
                                                            <IconWallet />
                                                        </div>
                                                        <div className="app">
                                                            <div className='text-dark'>{t('Your Balance')} {userCurrency}{walletBalance}</div>
                                                            <div className='text-dark'>{t('Available for Payment')}</div>
                                                        </div>
                                                        <p className="switch-item">
                                                            <label>
                                                                <input
                                                                    className="check border"
                                                                    type="checkbox"
                                                                    value="checkbox"
                                                                    name="check"
                                                                    checked={isChecked}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </label>
                                                        </p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </form>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="sidebar-shop my-4">
                                    <div className="sidebar-shop-item cart-totals">
                                        <div className="title">{t('Booking Details')}</div>
                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Booking Date')}</div>
                                            <div className="text-1">{bookedUserData?.bookingDate}</div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Check in')}</div>
                                            <div className="text-1">{bookedUserData?.checkIn}</div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Check out')}</div>
                                            <div className="text-1">{bookedUserData?.checkOut}</div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Number of Guest')}</div>
                                            <div className="text-1">{bookedUserData?.noGuest}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sidebar-shop">
                                    <div className="sidebar-shop-item cart-totals">
                                        <div className="title">{t('Cart Totals')}</div>
                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Amount')} ({bookedUserData?.days} {t('days')})</div>
                                            <div className="text-1">{userCurrency}{productPrice}</div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Tax')}({referApiData?.tax}%)</div>
                                            <div className="text-1">{userCurrency}{productTax}</div>
                                        </div>
                                        {couponStatus && couponAdd &&
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Coupon')}</div>
                                                <div className="text-1 text-danger">{userCurrency}{paybleCouponAmnt}</div>
                                            </div>
                                        }
                                        {isChecked &&
                                            <div className="flex items-center justify-between">
                                                <div className="text">{t('Wallet')}</div>
                                                <div className="text-1 text-danger">{userCurrency}{paybleWalletAmnt}</div>
                                            </div>
                                        }
                                        <div className="divider"></div>
                                        <div className="flex items-center justify-between">
                                            <div className="text">{t('Total Payble Amount')}</div>
                                            <div className="text-1">{userCurrency}{finalPrice}</div>
                                        </div>
                                        <a className="tf-button-primary justify-center w-full" onClick={handleToBook}>{t('Proceed to Checkout')}<i className="icon-arrow-right-add"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>

            </div>

            {isOpenModal && (
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}  >
                    <div className='p-5 w-100'>
                        <section >
                            <div >
                                <div>
                                    <h4 className="neutral-top">{t('Available coupons')}</h4>
                                    {couponData?.map((item) => (
                                        <div className='w-100 my-3'>
                                            <div className="categories-item wow fadeInUp" key={item?.id}>
                                                <div className="icon">
                                                    <img style={{ height: '40px', width: '40px' }} src={`${imageBaseUrl}${item?.c_img}`} alt="Avatar" />
                                                </div>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <div>
                                                        <div className="name">
                                                            <a>{item?.coupon_title}</a>
                                                        </div>
                                                        <div className="text">{item?.c_desc.slice(0, 75).concat('...')}</div>
                                                        <p><small className='mb-0 text-dark'>Ex {item?.cdate}</small></p>
                                                        <button onClick={() => {
                                                            handleClick(item?.id, item?.c_value, item?.coupon_code);
                                                            setIsOpenModal(false);
                                                        }}
                                                            // disabled={finalPrice <= item?.min_amt || finalPrice > paybleWalletAmnt}
                                                            disabled={finalPrice <= item?.min_amt}
                                                            className="tf-button-filter justify-center btn-filter col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">{couponAdd && couponId == item?.id ? 'Remove' : 'Use'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>

                </ReModal>
            )}

            {showPaymentList && (
                <ReModal isOpenModal={showPaymentList} onClose={() => setShowPaymentList(false)}  >
                    <div className='p-5 w-100'>
                        <section >
                            <div>
                                <div className='w-100'>
                                    <h4 className="neutral-top pb-5">{t('Select Payment Method')} </h4>
                                    <div className='d-flex row gap-5 align-items-center justify-content-between'>
                                        {filteredPaymentWay?.map((item) => (
                                            <div key={item?.id} onClick={() => { setSelectedPaymentType(item?.title); setSelectedPaymentId(item?.id) }} className={`categories-item col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 wow fadeInUp ${selectedPaymentId === item?.id ? 'active-map' : ''}`} >
                                                <div className="icon">
                                                    <img src={`${imageBaseUrl}${item?.img}`} alt={`${item?.title} icon`} />
                                                </div>
                                                <div>
                                                    <div className="name">
                                                        <a>{item?.title}</a>
                                                    </div>
                                                    <div className="text">{item?.subtitle}</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="button-submit mt-10">
                                            <button className="tf-button-primary justify-center" onClick={() => setPaymentTrigger(true)} >{t('Continue')}<i className="icon-arrow-right-add"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ReModal>
            )}

            {selectedPaymentType === 'Khalti Payment' && paymentTrigger && <KhaltiPayment product_name={producttitle} product_amount={finalPrice} booked_for={'booking'} />}
            {selectedPaymentType === 'FlutterWave' && paymentTrigger && <FlutterwavePayment product_name={producttitle} product_amount={finalPrice} product_id={bookedProductData?.propetydetails?.id} booked_for={'booking'} />}
            {selectedPaymentType === 'PayStack' && paymentTrigger && <PaystackPayment product_amount={finalPrice} booked_for={'booking'} />}
            {selectedPaymentType === 'Razorpay' && paymentTrigger && <RazorpayPayment product_amount={finalPrice} booked_for={'booking'} />}

            {isOpenModal && selectedPaymentType === 'Stripe' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/section/login.jpg" alt="" />
                        <h3>{t('Welcome to Your Real Estate Website')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex  flex-column'>
                            <StripePayment />
                        </form>
                    </div>
                </ReModal>
            }

            {isOpenModal && selectedPaymentType === 'Paypal' && paymentTrigger &&
                <ReModal isOpenModal={isOpenModal} onClose={closeModal}>
                    <div className="image-left">
                        <img src="./../assets/images/section/login.jpg" alt="" />
                        <h3>{t('Welcome to Your Real Estate Website')}</h3>
                    </div>
                    <div className="content-right p-5 ">
                        <form className='form-bacsic-infomation flex align-items-center justify-content-center d-flex flex-column'>
                            <PaypalPayment product_amount={finalPrice} />
                        </form>
                    </div>
                </ReModal>
            }

            {selectedPaymentType === 'Paytm' && paymentTrigger && <PaytmPayment product_amount={finalPrice} />}
            {selectedPaymentType === 'SenangPay' && paymentTrigger && <SenangpayPayment product_amount={finalPrice} />}
            {selectedPaymentType === 'Midtrans' && paymentTrigger && <MidtransPayment product_amount={finalPrice} />}
            {selectedPaymentType === 'Pay TO Owner' && paymentTrigger && <AddToProductPay paybleWalletAmnt={paybleWalletAmnt} />}
            {selectedPaymentType === 'wallet' && walletPay && <AddToProductPay paybleWalletAmnt={paybleWalletAmnt} />}
            {selectedPaymentType === '2checkout' && paymentTrigger && <TwoCheckoutPayment product_amount={finalPrice} />}
            {selectedPaymentType === 'Payfast' && paymentTrigger && <PayfastPayment product_name={producttitle} product_amount={finalPrice} booked_for={'booking'} />}
            {selectedPaymentType === 'MercadoPago' && paymentTrigger && <MercadoPagoPayment product_name={producttitle} product_amount={finalPrice} />}
            <Footer />
        </>
    )
}
