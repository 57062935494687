import React, { useEffect, useMemo, useState } from 'react'
import { useContextex } from '../context/useContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCountryData, fetchData, fetchSearchData, fetchUserData } from '../store/action';
import Footer from './Footer';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { getCountryListData, getData, getLoading, getSearchData, getUHomeData } from '../store/selector';

function Main() {
    const [categoriesList, setCategoriesList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const {
        setFeaturedPropList,
        isUserId,
        countryData,
        setCountryData,
        selectedCountryId,
        setCountryListData,
        setPropertyType,
        setCurrentPage,
    } = useContextex();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const uHomeData = useSelector(getUHomeData);
    const countryList = useSelector(getCountryListData);
    useEffect(() => {

        var flatAccordion = function (class1, class2) {
            var args = { duration: 400 };

            $(class2 + ' .toggle-title.active').siblings('.toggle-content').show();
            $(class1 + ' .toggle-title').on('click', function () {
                $(class1 + ' ' + class2).removeClass('active');
                $(this).closest(class2).toggleClass('active');

                if (!$(this).is('.active')) {
                    $(this).closest(class1).find('.toggle-title.active').toggleClass('active').next().slideToggle(args);
                    $(this).toggleClass('active');
                    $(this).next().slideToggle(args);
                } else {
                    $(class1 + ' ' + class2).removeClass('active');
                    $(this).toggleClass('active');
                    $(this).next().slideToggle(args);
                }
            });
        };

        return flatAccordion()

    }, [])

    useEffect(() => {
        // Ensure the loader is displayed only once during the initial load
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setCurrentPage('home');
    }, [setCurrentPage]);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchUserData({ uid: isUserId || 0, country_id: selectedCountryId || 0 }));
                navigate('/');
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, selectedCountryId, isUserId, navigate]);

    useEffect(() => {
        if (uHomeData) {
            setCategoriesList(uHomeData.HomeData?.Catlist || []);
            setFeaturedPropList(uHomeData?.HomeData?.Featured_Property || []);
            setPropertyType(uHomeData?.HomeData?.Catlist || []);
        }
    }, [uHomeData, setFeaturedPropList, setPropertyType]);

    useEffect(() => {
        const fetchCountryDataAsync = async () => {
            try {
                await dispatch(fetchCountryData({ uid: isUserId || '0' }));
            } catch (error) {
                console.error("Error fetching country data:", error);
            }
        };

        fetchCountryDataAsync();
    }, [dispatch, isUserId]);

    useEffect(() => {
        if (countryList?.CountryData) {
            setCountryData(countryList.CountryData);
            setCountryListData(countryList.CountryData);
        }
    }, [countryList, setCountryData, setCountryListData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="main-content spacing-20">
                <section className="slider home1">
                    <div className="wrap-slider">
                        <div className="slider-item">
                            <div className="cl-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="slider-content">
                                            <div className="sub wow fadeInUp" data-wow-delay="0.1s">
                                                {t('LET US GUIDE YOUR HOME')}
                                            </div>
                                            <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                                                {t(`Discover a place you'll love to live`)}
                                            </h1>
                                            <SearchField />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="space-20"></div>
                <FlateCities countryData={countryData} />
                <section className="tf-section work-with-us style-3">
                    <div className="cl-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-section text-center">
                                    <h2 className="wow fadeInUp">{t('Featured Properties')}</h2>
                                    <div className="text wow fadeInUp">
                                        {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-tabs style-1">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="widget-menu-tab">
                                        <Tabs />
                                    </ul>
                                </div>
                            </div>
                            <div className="widget-content-tab">
                                <div className="widget-content-inner active">
                                    <div className="row">
                                        <TabsCard />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <button
                                    className="tf-button-primary border-radius-corner wow fadeInUp"
                                    onClick={() => navigate('/product-all')}
                                >
                                    {t('See All Listing')}
                                    <i className="icon-arrow-right-add"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            {loading && (
                <div className="preload preload-container">
                    <div className="middle"></div>
                </div>
            )}
        </>
    );
}

export default Main

export const FlateCities = ({ countryData }) => {

    const { imageBaseUrl, setSelectedCountryId, selectedCountryId, setCountryListData } = useContextex();

    const countryList = useSelector(getCountryListData)
    const { t } = useTranslation();

    useEffect(() => {
        const defaultCity = countryList?.CountryData?.find(country => country?.d_con == '1')
        if (!selectedCountryId) {
            setSelectedCountryId(defaultCity?.id)
        }
    }, [countryList])

    useEffect(() => {
        // Ensure Swiper is available
        if (window.Swiper) {
            // Initialize Swiper only if the element exists
            const swiperContainer = document.querySelector('.slider-cities');
            if (swiperContainer) {
                const swiper = new window.Swiper(swiperContainer, {
                    spaceBetween: 25,
                    slidesPerView: 5,
                    observer: true,
                    observeParents: true,
                    pagination: {
                        el: '.cities-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        991: {
                            slidesPerView: 4,
                        },
                        1440: {
                            slidesPerView: 5,
                        },
                    },
                });
            }
        }
    }, []); // Empty dependency array to run once on mount


    const handleCountryClick = (itemId) => {
        setSelectedCountryId(itemId);
        const selectedCountry = countryList?.CountryData?.find(country => country?.id === itemId);
        if (selectedCountry) {
            setCountryListData([selectedCountry]);
        }
    };

    return (
        <>
            <section className="tf-section flat-cities style-1" >
                <div className="cl-container full">

                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section text-center">
                                <h2>{t('Find Properties in These Cities')}</h2>
                                <div className="text">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}.</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="wrap">
                                <div className="swiper-container padding-bottom-80 pagination-style-2 slider-cities">
                                    <div className="swiper-wrapper col-12" style={{marginBottom:'50px'}} >

                                        {countryData?.map((item) => (
                                            <div className={`swiper-slide pointer col-xs-8 col-sm-6 col-md-4 col-lg-2 ${selectedCountryId === item?.id && 'active-map'}`} key={item?.id} onClick={() => handleCountryClick(item?.id)} style={{ width: '324.8px', marginRight: '25px' }}>
                                                <div className="cities-item style-2 wow fadeInUp">
                                                    <img src={`${imageBaseUrl}${item?.img}`} alt={item?.title} />
                                                    <div className="content">
                                                        {/* <p>13 Properties</p> */}
                                                        <h4>{item?.title}</h4>
                                                    </div>
                                                    {/* <a className="button-arrow-right"><i className="icon-arrow-right-add"></i></a> */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="swiper-pagination cities-pagination "></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export const Tabs = () => {
    const { setFeaturedPropList, setSelectedId, isUserId, setPropertyType, selectedCountryId, selectedId, tabsList, setTabsList } = useContextex();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const uHomeData = useSelector(getUHomeData);
    const loading = useSelector(getLoading);

    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchUserData({ uid: isUserId || '0', country_id: selectedCountryId || '0' }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        navigate('/');

        fetchDataAndSetCategories();
    }, [dispatch, selectedCountryId, isUserId, navigate]);

    useEffect(() => {
        if (uHomeData) {
            setTabsList(uHomeData.HomeData?.Catlist);
            setFeaturedPropList(uHomeData?.HomeData?.Featured_Property);
            setPropertyType(uHomeData?.HomeData?.Catlist);
        }
    }, [uHomeData, setFeaturedPropList, setPropertyType]);

    useEffect(() => {
        var tabs = function () {
            $('.widget-tabs').each(function () {
                $(this).find('.widget-content-tab').children().hide();
                $(this).find('.widget-content-tab').children(".active").show();
                $(this).find('.widget-menu-tab').children('.item-title').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive);
                    contentActive.addClass('active').fadeIn("slow");
                    contentActive.siblings().removeClass('active');
                    $(this).addClass('active').parents('.widget-tabs').find('.widget-content-tab').children().eq(liActive).siblings().hide();
                });
            });
            $('.widget-tabs-1').each(function () {
                $(this).find('.widget-content-tab-1').children().hide();
                $(this).find('.widget-content-tab-1').children(".active-1").show();
                $(this).find('.widget-menu-tab-1').children('.item-title-1').on('click', function () {
                    var liActive = $(this).index();
                    var contentActive = $(this).siblings().removeClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive);
                    contentActive.addClass('active-1').fadeIn("slow");
                    contentActive.siblings().removeClass('active-1');
                    $(this).addClass('active-1').parents('.widget-tabs-1').find('.widget-content-tab-1').children().eq(liActive).siblings().hide();
                });
            });
        };

        return tabs()
    }, [])

    return (
        <>
            {tabsList?.map((item) => (
                <li key={item?.id} className={`item-title d-flex align-items-center ${selectedId === item?.id ? 'active' : ''}`} onClick={() => setSelectedId(item?.id)}>
                    <span className="inner">{item?.title}</span>
                </li>
            ))}
        </>
    )
}

export const TabsCard = () => {

    const data = useSelector(getData);
    const loading = useSelector(getLoading)
    const { pid } = useParams()

    const dispatch = useDispatch();
    const { setProductDetailId, productDetailId, imageBaseUrl, isUserId, selectedCountryId, userCurrency, setIsLikedOrNot, selectedId, currentPage, setTabCardData, tabCardData } = useContextex();

    const navigate = useNavigate()
    useEffect(() => {
        const fetchDataAndSetCategories = async () => {
            try {
                await dispatch(fetchData('u_cat_wise_property.php', { cid: selectedId, uid: isUserId || '0', country_id: selectedCountryId }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDataAndSetCategories();
    }, [dispatch, selectedId, selectedCountryId]);

    useEffect(() => {
        if (data && data?.Property_cat) {
            setTabCardData(data.Property_cat);
        }
    }, [data, setTabCardData]);

    const cuttedData = currentPage == 'home' ? tabCardData?.slice(0, 6) : tabCardData

    const handleItemClick = (item) => {
        const formattedText = item.title.replace(/\s+/g, '-').toLowerCase();
        setProductDetailId(item.id);
        navigate(`/${formattedText}`);
        sessionStorage.setItem('pid', item?.id);
    };

    return (
        <>
            {cuttedData?.map((item, index) => (
                <div key={index} className="col-xl-4 col-md-6 col-12 pointer" onClick={() => handleItemClick(item)}>
                    <div className="box-dream style-absolute type-no-bg-content" onClick={() => {
                        setProductDetailId(item?.id);
                        setIsLikedOrNot(prevState => !prevState);
                    }}>
                        <div className="image">
                            <div className="list-tags">
                                <a className="tags-item for-sell">FOR {item?.buyorrent == '2' ? 'BUY' : 'SALE'}</a>
                            </div>
                            <img className="w-full" style={{ minHeight: '400px', maxHeight: '400px' }} src={`${imageBaseUrl}${item?.image}`} alt={item?.title} />
                        </div>
                        <div className="content">
                            <div className="head">
                                <div className="title">
                                    <a>{item.title.substring(0, 35)}</a>
                                </div>
                            </div>
                            <div className="location">
                                <div className="icon">
                                    <i className="flaticon-location"></i>
                                </div>
                                <p>{item.city}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="icon-box">
                                    <div className="item">
                                        <img src="./assets/icon/badseet.png" alt='' />
                                        <p>{item?.beds}</p>
                                    </div>
                                    <div className="item">
                                        <img src="./assets/icon/bath.png" alt='' />
                                        <p>{item?.bathroom}</p>
                                    </div>
                                    <div className="item">
                                        <img src="./assets/icon/sqrfeet.png" alt='' />
                                        <p>{item?.sqrft}</p>
                                    </div>
                                </div>
                                <div className="price">{userCurrency ? userCurrency : '$'}{item.price}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            
        </>
    )
}

export const SearchField = () => {
    const [searchList, setSearchList] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [handleSearch, setHandleSearch] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pid } = useParams()
    const loading = useSelector(getLoading);
    const { isUserId, selectedCountryId, imageBaseUrl, setProductDetailId } = useContextex();

    const searchDetails = useSelector(getSearchData);

    useEffect(() => {
        if (searchDetails?.search_propety) {
            setSearchList(searchDetails?.search_propety);
        }
    }, [searchDetails]);

    useEffect(() => {
        if (searchedText.trim()) {
            const fetchSearchDataAsync = async () => {
                try {
                    await dispatch(fetchSearchData({
                        keyword: searchedText,
                        uid: isUserId || 0,
                        country_id: selectedCountryId || 1
                    }));
                } catch (error) {
                    console.error("Error fetching search data:", error);
                }
            };

            fetchSearchDataAsync();
        }
    }, [searchedText, dispatch, isUserId, selectedCountryId]);

    const handleItemClick = (item) => {
        const formattedText = item?.title.replace(/\s+/g, '_').toLowerCase();
        setProductDetailId(item?.id);
        navigate(`/${formattedText}`);
        sessionStorage.setItem('pid', formattedText);
    };

    return (
        <div className="widget-tabs">
            <div className="widget-content-tab">
                <div className="widget-content-inner active">
                    <form className="form-search-content flex-grow wow fadeInUp">
                        <fieldset className="name">
                            <input
                                type="text"
                                placeholder="Search Here..."
                                className="show-search style-2"
                                name="name"
                                tabIndex="2"
                                aria-required="true"
                                required
                                value={searchedText}
                                onChange={(e) => {
                                    setSearchedText(e.target.value);
                                    setHandleSearch(true);
                                }}
                            />
                        </fieldset>
                        <div className="button-submit style-absolute-right">
                            <button type="button" className="style-icon-bg">
                                <i className="flaticon-magnifiying-glass"></i>
                            </button>
                        </div>
                        <div className={`box-content-search ${handleSearch ? 'active' : ''}`}>
                            <ul>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    searchList?.map(item => (

                                        <li key={item.id} onClick={() => handleItemClick(item)}>
                                            <div className="item1">
                                                <div>
                                                    <div className="image">
                                                        <img src={`${imageBaseUrl}${item.image}`} alt={item.title} />
                                                    </div>
                                                    <p>{item.title}</p>
                                                </div>
                                                <div className="text">
                                                    {item.buyorrent == 1 ? 'Buy' : 'Sell'}
                                                </div>
                                            </div>
                                        </li>
                                    ))

                                )}
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};





