import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContextex } from '../context/useContext';
import { fetchCancelData, fetchSecoundData } from '../store/action';
import { ReModal } from '../partials/ReModal';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getCancelBook, getSecoundData } from '../store/selector';

const cancellationReasons = [
    { id: '1', title: 'Financing fell through' },
    { id: '2', title: 'Inspection issues' },
    { id: '3', title: 'Change in financial situation' },
    { id: '4', title: 'Title issues' },
    { id: '5', title: 'Seller changes their mind' },
    { id: '6', title: 'Competing offer' },
    { id: '7', title: 'Personal Reasons' },
    { id: '8', title: 'Others' },
];

function Cancel({ url,setIsCancelBtn }) {
    const [selectedReason, setSelectedReason] = useState(null);
    const [customReason, setCustomReason] = useState('');
    const [isCancle, setIsCancle] = useState(false);
    const [respData, setRespData] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const sdata = useSelector(getCancelBook);
    const { isUserId, bookingId, showCancelModal, setShowCancelModal } = useContextex();

    useEffect(() => {
        const cancelBooking = async () => {
            if (isCancle && selectedReason) {
                try {
                    const reason = selectedReason === 'Others' ? customReason : selectedReason;
                    await dispatch(fetchCancelData(url, { book_id: bookingId, uid: isUserId, cancle_reason: reason }));
                    await setShowSnackbar(true);
                    setIsCancelBtn(true)
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setIsCancle(false);
                }
            }
        };

        cancelBooking();
    }, [dispatch, selectedReason, bookingId, isUserId, isCancle, customReason]);

    useEffect(() => {
        if (sdata) {
            setRespData(sdata);
            // setShowCancelModal(false);
        }
    }, [sdata]);

    useEffect(() => {
        if (showCancelModal) {
            setIsCancle(false);
        }
    }, [showCancelModal]);

    const closeModal = () => setShowCancelModal(false);
    const handleCloseSnackbar = () => setShowSnackbar(false);

    return (
        <>
            <ReModal isOpenModal={showCancelModal} onClose={closeModal}>
                <div className="image-left">
                    <img src="./../assets/images/section/login.jpg" alt="" />
                    <h3>{t('Welcome to Your Real Estate Website')}</h3>
                </div>

                <div className="content-right p-5">
                    <form className='form-basic-information flex flex-column'>
                        <h4 className='d-flex justify-content-center'>{t('Cancel Booking Request')}</h4>
                        <ul className="flex flex-column gap15">
                            {cancellationReasons.map(item => (
                                <li className="radio-item" key={item.id}>
                                    <label>
                                        <p>{item.title}</p>
                                        <input
                                            type="radio"
                                            name="cancellationReason"
                                            id={`reason-${item.id}`}
                                            checked={selectedReason === item.title}
                                            onChange={() => setSelectedReason(item.title)}
                                        />
                                        <span className="btn-checkbox"></span>
                                    </label>
                                </li>
                            ))}
                        </ul>

                        {selectedReason === 'Others' && (
                            <fieldset className="name">
                                <input
                                    type="text"
                                    className='w-100'
                                    id="customReason"
                                    value={customReason}
                                    onChange={(e) => setCustomReason(e.target.value)}
                                    placeholder={t('Enter Reason')}
                                    required
                                />
                            </fieldset>
                        )}

                        <div className='col-12 mt-5 d-flex justify-content-between'>
                            <button
                                type="button"
                                className="tf-button-primary style-black active mx-2"
                                onClick={closeModal}
                            >
                                {t('Cancel')}
                            </button>
                            <button
                                type="button"
                                className="tf-button-primary active"
                                onClick={() => setIsCancle(true)}
                            >
                                {t('Confirm')} <i className="icon-arrow-right-add"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </ReModal>
            {showSnackbar && sdata?.ResponseMsg && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={sdata?.ResponseMsg} />}
        </>
    );
}

export default Cancel;
