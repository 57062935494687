import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { packagePlanBuy } from '../store/action';
import { useEffect, useState, useCallback } from 'react';
import { useContextex } from '../context/useContext';
import { CustomSnackbar } from '../utils/CustomSnackbar';

export const usePackagePurchase = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);

    const {
        isUserId,
        transactionId,
        selectedPackData,
        selectedPaymentType,
        setTransactionId,
        setSelectedPaymentId,
    } = useContextex();

    const purchasePackage = useCallback(async () => {
        // if (!isUserId || !selectedPackData?.id || !selectedPaymentType || !transactionId) {
        //     console.error('Required fields are missing for purchase.');
        //     return;
        // }

        setIsPurchasing(true);
        
        try {
            await dispatch(packagePlanBuy({
                uid: isUserId,
                transaction_id: selectedPackData?.price > 0 ? transactionId : 0,
                plan_id: selectedPackData.id ,
                pname: selectedPackData?.price > 0 ? selectedPaymentType : 'test',
            }));
            setShowSnackbar(true);
        } catch (error) {
            console.error('Error purchasing package:', error);
        } finally {
            setIsPurchasing(false);
        }
    }, [dispatch, isUserId, selectedPackData, selectedPaymentType, transactionId]);

    useEffect(() => {
        if (transactionId) {
            purchasePackage();
            navigate('/addProperty');
            setTransactionId('');
            setSelectedPaymentId('');
        }
    }, [transactionId, isPurchasing, purchasePackage, navigate, setTransactionId, setSelectedPaymentId]);

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            {showSnackbar && (
                <CustomSnackbar handleClose={handleCloseSnackbar} snack_message="Package Purchased Successfully" />
            )}
        </>
    );
};
