import { createSelector } from "reselect";

const getState = (state) => state;

export const getData = createSelector(
    [getState],
    (state) => state.data
);

export const getPackData = createSelector(
    [getState],
    (state) => state.packData
);

export const getError = createSelector(
    [getState],
    (state) => state.error
);

export const getSecoundData = createSelector(
    [getState],
    (state) => state.secoundData
);

export const getThirdData = createSelector(
    [getState],
    (state) => state.thirdData
);

export const getIsFavorite = createSelector(
    [getState],
    (state) => state.isFavorite
);

export const getLoading = createSelector(
    [getState],
    (state) => state.loading
);

export const getBookedDate = createSelector(
    [getState],
    (state) => state.bookedDate
);

export const getRegisterData = createSelector(
    [getState],
    (state) => state.registerUserData
);

export const getCheckAvailableData = createSelector(
    [getState],
    (state) => state.checkAvailable
);

export const getEnquiryData = createSelector(
    [getState],
    (state) => state.enquiryData
);

export const getPayoutData = createSelector(
    [getState],
    (state) => state.payoutData
);

export const getAuthTokenData = createSelector(
    [getState],
    (state) => state.authToken
);

export const getUHomeData = createSelector(
    [getState],
    (state) => state.uHomeData
);

export const getCountryListData = createSelector(
    [getState],
    (state) => state.countryList
);

export const getSendEnquiryData = createSelector(
    [getState],
    (state) => state.sendEnquirys
);

export const getAddPropertyData = createSelector(
    [getState],
    (state) => state.sendAddProparty
);

export const getUserPageData = createSelector(
    [getState],
    (state) => state.userPageTabs
);

export const getUserFavListData = createSelector(
    [getState],
    (state) => state.userFavList
);

export const getProdGallaryData = createSelector(
    [getState],
    (state) => state.prodGalList
);

export const getMessagesData = createSelector(
    [getState],
    (state) => state.messages
);

export const getSearchData = createSelector(
    [getState],
    (state) => state.searchDetails
);

export const getOTPData = createSelector(
    [getState],
    (state) => state.otpData
);

export const getPackageData = createSelector(
    [getState],
    (state) => state.packageDt
);

export const getCategoriesData = createSelector(
    [getState],
    (state) => state.galCat
);

export const getReviewData = createSelector(
    [getState],
    (state) => state.reviewData
);

export const getWalletReportData = createSelector(
    [getState],
    (state) => state.walletReport
);

export const getWalletUpdate = createSelector(
    [getState],
    (state) => state.upWallet
)

export const getReferData = createSelector(
    [getState],
    (state) => state.referResData
);

export const getProfileEditData = createSelector(
    [getState],
    (state) => state.proEdit
);

export const getLoginUserData = createSelector(
    [getState],
    (state) => state.loginUserData
);

export const getForgotPassData = createSelector(
    [getState],
    (state) => state.forgotPassData
);

export const getVarifyMobData = createSelector(
    [getState],
    (state) => state.varifyMobileDt
);

export const getFacilityData = createSelector(
    [getState],
    (state) => state.facilities
);

export const getBookData = createSelector(
    [getState],
    (state) => state?.bookedProduct
)

export const getAddRemoveFav = createSelector(
    [getState],
    (state) => state?.addRemoveDt
)

export const getCancelBook = createSelector(
    [getState],
    (state) => state.cancelBookData
)

export const getInquiryPro = createSelector(
    [getState],
    (state) => state.inquryReq
)

export const getMyPropBookHand = createSelector(
    [getState],
    (state) => state.myPropHandle
)

export const getReviewUp = createSelector(
    [getState],
    (state) => state.reviewUpdate
)



