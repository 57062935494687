import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContextex } from '../context/useContext';
import { fetchBookedProductData, fetchData, fetchWalletUpdate } from '../store/action';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { getData, getSecoundData, getWalletUpdate } from '../store/selector';

const snackMessage = 'Your transaction is successful';

export const AddToProductPay = ({ paybleWalletAmnt }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [bookedProductDetails, setBookedProductDetails] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        bookedUserData,
        productAmount,
        bookedProductData,
        transactionId,
        selectedPaymentId,
        isUserId,
        productFinalPrice,
        bookedOtherUserData,
        otherUserGender
    } = useContextex();

    const secoundData = useSelector(getSecoundData);
    const productTax = (productAmount?.bookingAmount || 0) * (productAmount?.bookingTax || 0) / 100;

    const bookingSuccessData = {
        prop_id: bookedProductData?.propetydetails?.id,
        uid: isUserId,
        check_in: bookedUserData?.checkIn,
        check_out: bookedUserData?.checkOut,
        subtotal: productAmount?.bookingAmount,
        total: productFinalPrice,
        total_day: productAmount?.bookingDays,
        cou_amt: productAmount?.bookingCouponAmount || '0',
        wall_amt: productAmount?.bookingWalletAmount || paybleWalletAmnt,
        transaction_id: (selectedPaymentId === '2' || selectedPaymentId === '5') ? '0' : transactionId,
        add_note: bookedUserData?.notes,
        prop_price: bookedProductData?.propetydetails?.price,
        book_for: bookedUserData?.bookedFor,
        p_method_id: selectedPaymentId,
        tax: productTax,
        noguest: bookedUserData?.noGuest,
        ...(bookedUserData?.bookedFor === 'other' && {
            fname: bookedOtherUserData?.firstName,
            lname: bookedOtherUserData?.lastName,
            gender: otherUserGender,
            email: bookedOtherUserData?.email,
            mobile: bookedOtherUserData?.phone,
            ccode: '+91',
            country: 'india'
        })
    };

    useEffect(() => {
        if (selectedPaymentId === '2' || selectedPaymentId === '5') {
            const bookProduct = async () => {
                try {
                    await dispatch(fetchBookedProductData(bookingSuccessData));
                    setShowSnackbar(true);
                } catch (error) {
                    console.error("Error booking product:", error);
                }
            };           
            bookProduct();
        }
    }, [dispatch]);

    useEffect(() => {
        if (secoundData) {
            setBookedProductDetails(secoundData);
            if (selectedPaymentId === '5' || selectedPaymentId === '2') {
                navigate('/dashboard');
            }
        } else {
            console.error("Payment details are null or undefined.");
        }
    }, [secoundData, navigate, selectedPaymentId]);

    const snackMessage = bookedProductDetails?.ResponseMsg;

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snackMessage={snackMessage} />}
        </>
    );
};
