import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth, useContextex } from '../context/useContext';
import { loginUserDetails, otpTypeRequest } from '../store/action';
import RegistrationPage from './RegistrationPage';
import ForgotPass from './ForgotPass';
import { CustomSnackbar } from '../utils/CustomSnackbar';
import { useTranslation } from 'react-i18next';
import { getAuthTokenData, getLoading, getLoginUserData, getSecoundData } from '../store/selector';

function LoginPage() {
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loginError, setLoginError] = useState('');
    const [otpType, setOtpType] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState('')
    const [showForgetPassMd, setShowForgetPassMd] = useState(false);
    const [forgetPassType, setForgetPassType] = useState(null);
    const [errors, setErrors] = useState({
        phoneNumber: '',
        password: ''
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authToken = useSelector(getAuthTokenData);
    const loading = useSelector(getLoading)
    const loginUserData = useSelector(getLoginUserData);
    const secoundData = useSelector(getSecoundData);
    const { login } = useAuth();
    const { setIsUser, setUserCurrency, setLoginModal, setLoginData, loginData, registerModal, setRegisterModal } = useContextex();

    useEffect(() => {
        const modalElement = document.getElementById('modallogin');
        const modal = new Modal(modalElement);
        modal.show();
    }, []);

    useEffect(() => {
        setSnackMsg(loginUserData?.ResponseMsg)
        if (loginUserData?.UserLogin) {
            const { id, currency } = loginUserData?.UserLogin;
            localStorage.setItem('uid', id);
            setIsUser(id);
            setUserCurrency(currency ? currency : '$');
            setLoginData(loginUserData);
            login(authToken);
            setLoginModal(false);
            // navigate('/')
        }
    }, [loginUserData, loginData, setIsUser, setUserCurrency, setLoginData]);

    useEffect(() => {
        if (forgetPassType) {
            dispatch(otpTypeRequest()).catch(console.error);
        }
    }, [forgetPassType, dispatch]);

    useEffect(() => {
        if (secoundData?.SMS_TYPE) {
            setOtpType(secoundData.SMS_TYPE);
        } else {
            console.error("secoundData is missing SMS_TYPE.");
        }
    }, [secoundData]);

    const validateInputs = () => {
        const newErrors = { name: '', email: '', password: '', phoneNumber: '' };
        let isValid = true;

        if (!phoneNumber) {
            newErrors.phoneNumber = "Phone number can't be null";
            isValid = false;
        }

        if (!password) {
            newErrors.password = "password can't be null";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) return;

        const countryCallingCode = `+${phoneNumber.slice(0, 2)}`;
        const mobileNumber = phoneNumber.slice(2);

        try {
            await dispatch(loginUserDetails({ mobile: mobileNumber, ccode: countryCallingCode, password }));
            await setShowSnackbar(true);
            // navigate('/');
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    const handleForgotPass = () => {
        setShowForgetPassMd(prev => !prev);
        setForgetPassType(prev => !prev);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <>
            <div className="modal fade modalCenter" id="modallogin">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content modal-sm">
                        <button type="button" className="btn-hide-modal" data-bs-dismiss="modal">
                            <i className="icon-close"></i>
                        </button>
                        <div className="image-left">
                            <img src="./assets/images/section/login.jpg" alt="Login" />
                            <h3>{t('Welcome to Your Real Estate Website')}</h3>
                        </div>
                        <div className="content-right">
                            <h4>{t('Sign into your account')}</h4>

                            <form className="form-login" onSubmit={handleSubmit}>

                                <fieldset className="phonenumber">
                                    <input
                                        className={errors.phoneNumber ? 'border border-danger' : ''}
                                        type="tel"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                    <span className='text-danger mx-4'>{errors.phoneNumber}</span>
                                </fieldset>

                                <fieldset className="password">
                                    <input
                                        className={errors.password ? 'border border-danger' : ''}
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className='text-danger mx-4'>{errors.password}</span>
                                </fieldset>

                                <div className="flex items-center justify-between">
                                    <div className="checkbox-item">
                                        <label>
                                            <p>{t('Remember me')}</p>
                                            <input type="checkbox" />
                                            <span className="btn-checkbox"></span>
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        className="lost-password"
                                        onClick={handleForgotPass}
                                    >
                                        {t('Lost your password')}?
                                    </button>
                                </div>

                                <div className="button-submit">
                                    <button
                                        className="tf-button-primary justify-center w-full"
                                        type="submit"
                                    >
                                        {t('Login')}
                                        <i className="icon-arrow-right-add"></i>
                                    </button>
                                </div>

                            </form>

                            <div className="flex items-center justify-center">
                                <p>{t('Not a member')}?</p>
                                <button
                                    className="btn-show-register"
                                    onClick={() => setRegisterModal(prev => !prev)}
                                >
                                    {t('Register here')}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {registerModal && <RegistrationPage />}
            {showForgetPassMd && <ForgotPass otpType={otpType} />}
            {showSnackbar && <CustomSnackbar handleClose={handleCloseSnackbar} snack_message={snackMsg} />}

        </>
    );
}

export default LoginPage;
